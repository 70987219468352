import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Container, Hero, Layout, RegistrationForm } from '../components'
import { Flex, Box, Heading, Text } from 'rebass'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import { format, parse, isEqual } from 'date-fns'

const Schedule = styled(Text)`
  h4 {
    margin-top: ${themeGet('space.3')}px;
    margin-bottom: ${themeGet('space.1')}px;
  }

  .schedule {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 8px;
    background-color: ${themeGet('colors.mysore')};
    color: white;
    padding: ${themeGet('space.3')}px;
    margin-bottom: ${themeGet('space.2')}px;

    &.mysore {
      background-color: ${themeGet('colors.mysore')};
    }
    &.led {
      background-color: ${themeGet('colors.led')};
    }
    &.beginner {
      background-color: ${themeGet('colors.beginner')};
    }
    &.technique {
      background-color: ${themeGet('colors.technique')};
    }
    &.other {
      background-color: ${themeGet('colors.other')};
    }

    .description {
      width: 100%;
      font-size: ${themeGet('fontSizes.1')}px;
    }
  }
`

const EventTemplate = ({ data }) => {
  const {
    name,
    slug,
    startDate,
    endDate,
    description,
    body,
    schedule,
    featuredImages,
    registrationClosed,
    eventType,
  } = data.contentfulEvent

  const dateRange = isEqual(startDate, endDate)
    ? format(endDate, 'D.M.YYYY')
    : format(startDate, 'D.M.') + '–' + format(endDate, 'D.M.YYYY')

  data.contentfulEvent.dateRange = dateRange

  return (
    <Layout>
      <Helmet>
        <title>
          {name} {dateRange} – {config.siteTitle}
        </title>
        <meta
          name="description"
          content={
            description ? description.description : config.siteDescription
          }
        />
        <meta
          property="og:title"
          content={`${name} ${dateRange} – ${config.siteTitle}`}
        />
        <meta property="og:url" content={`${config.siteUrl}/kurssit/${slug}`} />
        <meta
          property="og:description"
          content={
            description ? description.description : config.siteDescription
          }
        />
        <meta property="og:image" content={featuredImages[0].fluid.src} />
      </Helmet>

      <Hero image={featuredImages[0]} title={name} excerpt={dateRange} />

      {(description || body) && (
        <Container py={[3, 4]} maxWidth="56em">
          {description && (
            <Text fontSize={[3, 4, 5]} mb={5}>
              {description.description}
            </Text>
          )}
          {body && (
            <Container px={0} maxWidth="40em">
              <Text
                fontSize={[2, null, 3]}
                dangerouslySetInnerHTML={{
                  __html: body.childMarkdownRemark.html,
                }}
              />
            </Container>
          )}
        </Container>
      )}

      <Container py={[4, 5, 6]} px={[3, null, 5]} maxWidth="80em">
        <Flex justifyContent={[null, 'space-between']} flexWrap="wrap">
          <Box width={[1, null, 2 / 5, 2 / 5]} mb={[4, null, 0]}>
            <Heading
              fontWeight="normal"
              fontSize={[4, 5, null, 6]}
              lineHeight="1"
              mb={4}
            >
              Aikataulut ja ilmoittautuminen
            </Heading>
            {schedule && (
              <Schedule
                fontSize={[null, 2]}
                dangerouslySetInnerHTML={{
                  __html: schedule.childMarkdownRemark.html,
                }}
              />
            )}
            {data.contentfulEvent.classes && Date.now() <= parse(endDate) && (
              <Text fontSize={1} mt={5}>
                <p>
                  Sitova ilmoittautuminen tapahtuu täyttämällä yhteistietolomake
                  haluamasi kurssin kohdalla. Kurssi- ja kausimaksut on
                  maksettava ennen kurssin alkua Astanga Jooga Lappi ry:n
                  tilille <strong>käyttämällä oheisia viitenumeroita.</strong>
                </p>
                <p>
                  Erityisjärjestelyitä varten ota yhtettä Janiin (jani.roine @
                  gmail.com).
                </p>
                <p>
                  <strong>Pankkiyhteys</strong>
                  <br />
                  Astanga jooga Lappi ry,
                  <br />
                  Nordea 113430-641705
                  <br />
                  <strong>IBAN: FI9011343000641705</strong>
                  <br />
                  BIC: NDEAFIHH
                </p>
                <hr />
                <p>
                  Maksuvälineenä käyvät myös:
                  <ul>
                    <li>Eazybreak</li>
                    <li>Edenred</li>
                    <li>Epassi</li>
                    <li>Smartum</li>
                    <li>Tyky</li>
                    <li>Virikeseteli</li>
                  </ul>
                  Muistathan kirjoittaa palvelukuvaukseen mitä maksu koskee ja
                  kurssin <strong>viitenumero</strong>.
                </p>
                <p>
                  Jos maksat verkossa tai mobiilisovelluksessa, niin voitko
                  ystävällisesti lähettää kuitin tai screenshotin Janille
                  (jani.roine @ gmail.com) kirjanpitoa varten, kiitos.
                </p>
                {eventType === 'Weekly Practise' && (
                  <Text fontSize={1} pb={3}>
                    Tarjoamme opiskelijoille hyvinvointipassilla 25 % ja
                    opiskelijakortilla 20 % alennusta viikkotunneista. Voit
                    halutessasi maksaa kausimaksut 2. erässä. Ensimmäinen maksu
                    suoritettava kauden alussa ja toinen erä maaliskuun lopulla.
                    On mahdollista osallistua harjoituksiin myös kertamaksulla
                    12 €; maksu käteisenä tai etukäteen tilille viitenumerolla
                    2163 (ota kuitti mukaan). Huom. opiskelijoille ja
                    työttömille alennettu hinta 9 €.
                  </Text>
                )}
              </Text>
            )}
          </Box>
          {data.contentfulEvent.classes && (
            <Box width={[1, null, 1 / 2, 4 / 9]}>
              {Date.now() <= parse(endDate) ? (
                <Box>
                  <Text fontSize={[2, null, 3, 4]} mb={2}>
                    {registrationClosed ? (
                      <Text
                        color="white"
                        bg="mysore"
                        p={3}
                        textAlign="center"
                        style={{ borderRadius: '8px' }}
                      >
                        <strong>Kurssi on täynnä!</strong>
                      </Text>
                    ) : (
                      <Text>
                        <strong>
                          Ilmoittaudu kurssille alla olevalla lomakkeella.
                        </strong>
                        Muistathan käyttää aina viitenumeroa maksaessasi.
                        Tunneille osallistuminen edellyttää joogayhdistyksen
                        jäsenyyttä.
                      </Text>
                    )}
                  </Text>
                  <RegistrationForm
                    event={data.contentfulEvent}
                    disabled={registrationClosed}
                  />
                </Box>
              ) : (
                <Box py={4} bg="darken.0" style={{ borderRadius: '8px' }}>
                  <Text textAlign="center">
                    {registrationClosed ? (
                      <strong>Kurssi on täynnä.</strong>
                    ) : (
                      <strong>Ilmoittautuminen kurssille on päättynyt.</strong>
                    )}
                  </Text>
                </Box>
              )}
            </Box>
          )}
        </Flex>
      </Container>
    </Layout>
  )
}

export default EventTemplate

export const query = graphql`
  query eventQuery($slug: String!) {
    contentfulEvent(slug: { eq: $slug }) {
      id
      contentful_id
      title
      name
      slug
      eventType
      startDate
      endDate
      registrationClosed
      description {
        description
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      schedule {
        childMarkdownRemark {
          html
        }
      }
      featuredImages {
        title
        description
        fluid(maxWidth: 1440, quality: 60) {
          ...GatsbyContentfulFluid
        }
      }
      additionalInformation {
        childMarkdownRemark {
          html
        }
      }
      classes {
        id
        contentful_id
        slug
        name
        price
        discountedPrice
        referenceNumber
      }
      calendar {
        contentful_id
        startTime
        endTime
        participants {
          id
        }
        maxParticipants
      }
    }
  }
`
